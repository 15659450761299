import { ValidationRule } from 'vuelidate/lib/validators'

export const platformOSOptions = [
  {
    label: 'iOS',
    value: 'IOS',
  },
  {
    label: 'Android',
    value: 'ANDROID',
  },
]

export const platformValidationOptions = [
  {
    label: 'Equal',
    value: 'EQUAL',
  },
  {
    label: 'Greater Than',
    value: 'GREATER_THAN',
  },
  {
    label: 'Lower Than',
    value: 'LOWER_THAN',
  },
  {
    label: 'None',
    value: 'NONE',
  },
]

export const imageOptions = [
  {
    label: 'Static Image',
    value: 'static',
  },
  {
    label: 'Animation Image',
    value: 'animation',
  },
]

export interface ValidationsInterface {
  form: {
    category: {
      required: () => ValidationRule
    }
    targetUser: {
      required: () => ValidationRule
    }
    fileUsers: {
      name: Record<never, unknown>
    }
    membership: Record<never, unknown>
    cities: Record<never, unknown>
    platformVersion: {
      requiredIf: ValidationRule
    }
    title: {
      required: () => ValidationRule
      maxLength: ValidationRule
    }
    wording: {
      required: () => ValidationRule
      maxLength: ValidationRule
    }
    content: {
      required: () => ValidationRule
      maxLength: (value: string) => boolean
    }
    publishDate: {
      required: () => ValidationRule
    }
    publishTime: {
      required: () => ValidationRule
    }
    targetUrl: {
      url: Record<never, unknown>
    }
  }
}
