import {
  CreatePNRequestInterface,
  UpdatePNRequestInterface,
} from '@/data/payload/contracts/PNRequest'

export class CreatePNRequest implements CreatePNRequestInterface {
  public notificationTitle: string
  public notificationWording: string
  public notificationContent: string
  public notificationTarget: string
  public publishedAt: string
  public selectedUser?: string
  public city?: string
  public membershipLevel?: string
  public specificPlatformOs?: string
  public specificPlatformVersionValidation?: string
  public specificPlatformVersion?: string
  public notificationImage?: Blob
  public targetUrl?: string
  public ctaName?: string
  public category?: string
  public imageOption?: string

  constructor(
    notificationTitle: string,
    notificationWording: string,
    notificationContent: string,
    notificationTarget: string,
    publishedAt: string,
    selectedUser?: string,
    city?: string,
    membershipLevel?: string,
    specificPlatformOs?: string,
    specificPlatformVersionValidation?: string,
    specificPlatformVersion?: string,
    notificationImage?: Blob,
    targetUrl?: string,
    ctaName?: string,
    category?: string,
    imageOption?: string
  ) {
    this.notificationTitle = notificationTitle
    this.notificationWording = notificationWording
    this.notificationContent = notificationContent
    this.notificationTarget = notificationTarget
    this.publishedAt = publishedAt
    this.selectedUser = selectedUser
    this.city = city
    this.membershipLevel = membershipLevel
    this.specificPlatformOs = specificPlatformOs
    this.specificPlatformVersionValidation = specificPlatformVersionValidation
    this.specificPlatformVersion = specificPlatformVersion
    this.notificationImage = notificationImage
    this.targetUrl = targetUrl
    this.ctaName = ctaName
    this.category = category
    this.imageOption = imageOption
  }

  public toPayload(): FormData {
    // Specific for form data, this toPayload() will actually convert them into FormData
    const data = new FormData()
    data.append('notification_title', this.notificationTitle as string)
    data.append('notification_wording', this.notificationWording as string)
    data.append('notification_content', this.notificationContent as string)
    data.append('notification_target', this.notificationTarget as string)
    data.append('published_at', this.publishedAt as string)
    data.append('selected_user', this.selectedUser as string)
    data.append('city_residence', this.city as string)
    data.append('membership_level', this.membershipLevel as string)
    data.append('specific_platform_os', this.specificPlatformOs as string)
    data.append(
      'specific_platform_version_validation',
      this.specificPlatformVersionValidation as string
    )
    data.append(
      'specific_platform_version',
      this.specificPlatformVersion as string
    )
    this.notificationImage &&
      data.append(
        this.imageOption === 'static'
          ? 'notification_image'
          : 'notification_gif',
        this.notificationImage
      )
    data.append('target_url', this.targetUrl as string)
    data.append('cta_name', this.ctaName as string)

    data.append('notification_category', this.category as string)

    return data
  }
}

export class UpdatePNRequest implements UpdatePNRequestInterface {
  public notificationTitle: string
  public notificationWording: string
  public notificationContent: string
  public publishedAt: string
  public notificationImage?: Blob | null
  public targetUrl?: string
  public ctaName?: string
  public category?: string
  public imageOption?: string
  public programMembershipIDs?: number[] | null

  constructor(
    notificationTitle: string,
    notificationWording: string,
    notificationContent: string,
    publishedAt: string,
    notificationImage?: Blob | null,
    targetUrl?: string,
    ctaName?: string,
    category?: string,
    imageOption?: string,
    programMembershipIDs?: number[] | null
  ) {
    this.notificationTitle = notificationTitle
    this.notificationWording = notificationWording
    this.notificationContent = notificationContent
    this.publishedAt = publishedAt
    this.notificationImage = notificationImage
    this.targetUrl = targetUrl
    this.ctaName = ctaName
    this.category = category
    this.imageOption = imageOption
    this.programMembershipIDs = programMembershipIDs
  }

  public toPayload(): FormData {
    // Specific for form data, this toPayload() will actually convert them into FormData
    const data = new FormData()
    data.append('notification_title', this.notificationTitle as string)
    data.append('notification_wording', this.notificationWording as string)
    data.append('notification_content', this.notificationContent as string)
    data.append('published_at', this.publishedAt as string)

    if (this.notificationImage) {
      if (this.imageOption === 'static') {
        data.append('notification_image', this.notificationImage)
        data.append('notification_gif', '')
      } else {
        data.append('notification_gif', this.notificationImage)
        data.append('notification_image', '')
      }
    }
    data.append('target_url', this.targetUrl as string)
    data.append('cta_name', this.ctaName as string)
    data.append('notification_category', this.category as string)

    // // TODO: uncomment after feature edit membership option released on Backend
    // if (this.programMembershipIDs && this.programMembershipIDs.length > 0) {
    //   data.append('membership_ids', this.programMembershipIDs.join(','))
    // }

    return data
  }
}
