import { container } from 'tsyringe'
import dayjs from 'dayjs'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import {
  PushNotification,
  PushNotificationProgramMembership,
  PushNotifications,
} from '@/domain/entities/PN'
import { Pagination } from '@/domain/entities/Pagination'
import { CreatePNRequest, UpdatePNRequest } from '@/data/payload/api/PNRequest'
import { PNPresenter } from '@/app/ui/presenters/PNPresenter'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { PUSH_NOTIFICATION_PAGINATION } from '@/app/infrastructures/misc/Constants'

interface Dropdown {
  label: string
  value: string
}

export interface PNState {
  isLoading: boolean
  isLoadingRoute: boolean
  isLoadingCancel: boolean
  isInvalidExcel: boolean
  pnPayload: CreatePNRequest
  pnData: PushNotification[]
  pnDetail: PushNotification
  paginationData: Pagination
  categoryOptions: Dropdown[]
  isLoadingCategory: boolean
  listProgramMembership: PushNotificationProgramMembership[]
  hasDataProgramMembership: string
  errorCreateNotification: string | null
}

@Module({ namespaced: true, store, name: 'push-notification', dynamic: true })
class PNController extends VuexModule implements PNState {
  private presenter: PNPresenter = container.resolve(PNPresenter)
  public targetOptions = [
    {
      label: 'Selected Users',
      value: 'SELECTED_USERS',
    },
    {
      label: 'All Login Users',
      value: 'ALL_LOGIN_USERS',
    },
    {
      label: 'City of Residence',
      value: 'CITY_RESIDENCE',
    },
    {
      label: 'Membership Level',
      value: 'MEMBERSHIP_LEVEL',
    },
    {
      label: 'Specific Platform',
      value: 'SPECIFIC_PLATFORM',
    },
  ]
  public linkOptions = [
    {
      label: 'URL link',
      value: 'url',
    },
    {
      label: 'CTA Name',
      value: 'cta',
    },
  ]
  public membershipOptions = [
    {
      label: 'Basic',
      value: 'Basic',
    },
    {
      label: 'Regular',
      value: 'Regular',
    },
    {
      label: 'Premium',
      value: 'Premium',
    },
  ]
  public isLoading = false
  public isLoadingRoute = false
  public isLoadingCancel = false
  public isInvalidExcel = false
  public pnPayload = new CreatePNRequest('', '', '', '', '')
  public pnData = [new PushNotification()]
  public pnDetail = new PushNotification()
  public paginationData = new Pagination(1, PUSH_NOTIFICATION_PAGINATION, 0)
  public categoryOptions: Dropdown[] = []
  public isLoadingCategory = false
  public listProgramMembership: PushNotificationProgramMembership[] = []
  public hasDataProgramMembership = ''
  public errorCreateNotification: string | null = null
  public cancelTrigger = false

  get isEditable() {
    return !Utils.isGuest() &&
      this.pnDetail.status !== 'CANCELLED' &&
      this.pnDetail.status !== 'PUBLISHED'
      ? true
      : false
  }

  @Action({ rawError: true })
  public createPN(form: {
    title: string
    wording: string
    content: string
    targetUser: Record<string, any>
    fileUsers: File
    cities: Record<string, any>[]
    membership: string[]
    platformOS: Record<string, any>
    platformValidation: Record<string, any>
    platformVersion: string
    publishDate: string
    publishTime: string
    pnImage: File | undefined
    targetUrl: string
    ctaName: string
    category: Record<string, string>
    imageOption: Record<string, string>
  }) {
    let isValid = true

    this.setInvalidExcel(false)
    this.setLoading(true)
    this.setErrorCreateNotification(null)

    const reader = new FileReader()
    reader.readAsArrayBuffer(form.fileUsers)
    const payload = new CreatePNRequest(
      form.title,
      form.wording,
      form.content,
      form.targetUser.value,
      dayjs(
        `${form.publishDate} ${form.publishTime}`,
        'YYYY-MM-DD HH:mm:ss'
      ).format(),
      undefined,
      form.cities.map(item => item.value).join(','),
      form.membership.map(val => Number(val)).join(','),
      form.platformOS.value,
      form.platformValidation.value,
      form.platformVersion,
      form.pnImage?.name ? form.pnImage : undefined,
      form.targetUrl,
      form.ctaName,
      form.category.value,
      form.imageOption.value
    )

    if (form.targetUser === this.targetOptions[0]) {
      reader.onload = e => {
        try {
          const data = new Uint8Array(e.target?.result as Iterable<number>)
          import('xlsx').then(XLSX => {
            const workbook = XLSX.read(data, { type: 'array' })
            const sheetName = workbook.SheetNames[0] // Assume it is always in the first sheet
            const worksheet = workbook.Sheets[sheetName]
            const fileUsers = XLSX.utils
              .sheet_to_json(worksheet)
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .map((it: any) => {
                if (it instanceof Object && 'phone_number' in it) {
                  return Utils.countryIndonesiaPhoneNumber(it.phone_number)
                } else {
                  this.setInvalidExcel(true)
                  throw new Error(
                    'Invalid Excel data format: header column phone_number should be exist'
                  )
                }
              })
              .join(',')

            payload.selectedUser = fileUsers
            this.setCreatePNPayload(payload)
          })
        } catch (error) {
          console.error('Convert XLSX Error:', error)
          Vue.notify({
            title: 'Submitting Failed',
            text: 'Invalid Attachment: ' + error,
            type: 'error',
            duration: 8000,
          })
          isValid = false
        }
      }
    } else {
      this.setCreatePNPayload(payload)
    }

    reader.onloadend = () => {
      if (isValid) {
        this.presenter
          .create(this.pnPayload)
          .then(() => {
            this.setErrorCreateNotification('')
          })
          .catch(error => {
            if (this.isInvalidExcel) {
              Vue.notify({
                title: 'Create Push Notification Failed',
                text: `Excel must have header(phone_number) and user's phone number`,
                type: 'error',
                duration: 5000,
              })
            } else {
              this.setErrorCreateNotification(
                error.status === 400 || error.status === 422
                  ? error.error.message.en
                  : 'Something wrong'
              )
            }
          })
          .finally(() => {
            this.setLoading(false)
          })
      } else {
        this.setLoading(false)
      }
    }
  }

  @Action({ rawError: true })
  public updatePN(form: {
    id: string
    title: string
    wording: string
    content: string
    publishDate: string
    publishTime: string
    pnImage: File | undefined
    targetUrl: string
    ctaName: string
    category: Record<string, string | undefined>
    imageOption: Record<string, string>
    programMembershipIDs: number[] | null
  }) {
    this.setLoading(true)
    this.setErrorCreateNotification(null)

    this.presenter
      .update(
        form.id,
        new UpdatePNRequest(
          form.title,
          form.wording,
          form.content,
          dayjs(
            `${form.publishDate} ${form.publishTime}`,
            'YYYY-MM-DD HH:mm:ss'
          ).format(),
          form.pnImage,
          form.targetUrl,
          form.ctaName,
          form.category.value,
          form.imageOption.value,
          form.programMembershipIDs
        )
      )
      .then(() => {
        this.setErrorCreateNotification('')
      })
      .catch(error => {
        this.setErrorCreateNotification(
          error.status === 400 || error.status === 422
            ? error.error.message.en
            : 'Something wrong'
        )
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getPNList(params: Record<string, any>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setPNData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Push Notifications Error',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public cancelPN(id: string): void {
    this.setCancelLoading(true)
    this.setCancelTrigger(false)

    this.presenter
      .cancel(id)
      .then(() => {
        this.setCancelTrigger(true)
      })
      .catch(error => {
        this.setCancelTrigger(false)
        Vue.notify({
          title: 'Cancel Push Notification Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setCancelLoading(false)
      })
  }

  @Action({ rawError: true })
  public getPNDetail(id: string) {
    this.setLoading(true)
    this.presenter
      .get(id)
      .then(res => {
        this.setPNDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch PN Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getDetailOnEdit(id: string) {
    this.setLoading(true)
    this.setHasDataProgramMembership('')
    this.setPNDetail(new PushNotification())

    Promise.all([this.presenter.get(id), this.presenter.getCategoryOptions()])
      .then(res => {
        this.setPNDetail(res[0])
        this.setCategoryOptions(res[1])
        return this.presenter.getListProgramMembership()
      })
      .then(res => {
        this.setListProgramMembership(res)
        if (res.length === 0) {
          this.setHasDataProgramMembership('empty')
        }
      })
      .catch(error => {
        this.setHasDataProgramMembership('')
        if (
          error.status === 400 &&
          error.error.message.en.includes('no active program')
        ) {
          this.setHasDataProgramMembership('empty')
        } else {
          Vue.notify({
            title: this.setTitleError(
              error.config.url,
              'Fetch Data Detail is Failed'
            ),
            text: [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        }
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setCreatePNPayload(payload: CreatePNRequest) {
    payload.selectedUser =
      payload.notificationTarget === this.targetOptions[0].value
        ? payload.selectedUser
        : ''
    payload.city =
      payload.notificationTarget === this.targetOptions[2].value
        ? payload.city
        : ''
    payload.membershipLevel =
      payload.notificationTarget === this.targetOptions[3].value
        ? payload.membershipLevel
        : ''
    payload.specificPlatformOs =
      payload.notificationTarget === this.targetOptions[4].value
        ? payload.specificPlatformOs
        : ''
    payload.specificPlatformVersionValidation =
      payload.notificationTarget === this.targetOptions[4].value
        ? payload.specificPlatformVersionValidation
        : ''
    payload.specificPlatformVersion =
      payload.notificationTarget === this.targetOptions[4].value
        ? payload.specificPlatformVersion
        : ''

    this.pnPayload = payload
  }

  @Action({ rawError: true })
  public getCategoryOptions() {
    this.setCategoryLoading(true)
    this.presenter
      .getCategoryOptions()
      .then(res => {
        this.setCategoryOptions(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Category Failed',
          text:
            error.status === 400 && error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setCategoryLoading(false)
      })
  }

  @Action({ rawError: true })
  public getListOptions() {
    this.setLoading(true)
    this.setHasDataProgramMembership('')

    this.presenter
      .getCategoryOptions()
      .then(res => {
        this.setCategoryOptions(res)
        return this.presenter.getListProgramMembership()
      })
      .then(res => {
        this.setListProgramMembership(res)
        if (res.length === 0) {
          this.setHasDataProgramMembership('empty')
        }
      })
      .catch(error => {
        this.setHasDataProgramMembership('')
        if (
          error.status === 400 &&
          error.error.message.en.includes('no active program')
        ) {
          this.setHasDataProgramMembership('empty')
        } else {
          Vue.notify({
            title: this.setTitleError(
              error.config.url,
              'Fetch List Options was Failed'
            ),
            text: [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        }
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public setTitleError(errConfigURL: string, withDefault: string): string {
    if (errConfigURL.endsWith('category/option')) {
      return `Fetch Notification's Categories is Failed`
    }

    if (errConfigURL.endsWith('level/active')) {
      return `Fetch List Program Membership Level is Failed`
    }

    if (errConfigURL.endsWith('account/notification')) {
      return `Fetch Detail Notification is Failed`
    }

    return withDefault
  }

  @Action({ rawError: true })
  public getListProgramMembership() {
    this.setLoading(true)
    this.setHasDataProgramMembership('')

    this.presenter
      .getListProgramMembership()
      .then(res => {
        this.setListProgramMembership(res)
        if (res.length === 0) {
          this.setHasDataProgramMembership('empty')
        }
      })
      .catch(error => {
        this.setHasDataProgramMembership('')
        if (
          error.status === 400 &&
          error.error.message.en.includes('no active program')
        ) {
          this.setHasDataProgramMembership('empty')
        } else {
          Vue.notify({
            title: 'Fetch List Options was Failed',
            text: [400, 422].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        }
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setListProgramMembership(
    val: PushNotificationProgramMembership[]
  ): void {
    this.listProgramMembership = val
  }

  @Mutation
  private setHasDataProgramMembership(val: string): void {
    this.hasDataProgramMembership = val
  }

  @Mutation
  private setErrorCreateNotification(val: string | null): void {
    this.errorCreateNotification = val
  }

  @Mutation
  private setPNData(pn: PushNotifications) {
    this.paginationData = pn.pagination as Pagination
    this.pnData = pn.data as PushNotification[]
  }

  @Mutation
  private setPNDetail(pn: PushNotification) {
    this.pnDetail = pn
  }

  @Mutation
  public setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  public setRouteLoading(bool: boolean) {
    this.isLoadingRoute = bool
  }

  @Mutation
  public setCancelLoading(bool: boolean) {
    this.isLoadingCancel = bool
  }

  @Mutation
  public setInvalidExcel(bool: boolean) {
    this.isInvalidExcel = bool
  }

  @Mutation
  private setCategoryOptions(categories: string[]) {
    this.categoryOptions = categories.map(category => {
      let label = category.toLocaleLowerCase()
      label = label.charAt(0).toUpperCase() + label.slice(1)

      return {
        value: category,
        label,
      }
    })
  }

  @Mutation
  public setCategoryLoading(bool: boolean) {
    this.isLoadingCategory = bool
  }

  @Mutation
  public setCancelTrigger(success: boolean) {
    this.cancelTrigger = success
  }
}

export default getModule(PNController)
