







































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/PNController'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import dayjs from 'dayjs'
import { PushNotification } from '@/domain/entities/PN'
import ConfirmationIcon from '@/app/ui/assets/icon_confirmation.vue'
import AddCircle from '@/app/ui/assets/add_circle.vue'
import { PUSH_NOTIFICATION_PAGINATION } from '@/app/infrastructures/misc/Constants'
import { platformValidationOptions } from '@/app/infrastructures/misc/Constants/pushNotification'

type fakeDictionary<T> = { [key: string]: T }

interface IParams {
  page: number
  perPage: number
  search: string | null
}

interface IParamsData extends IParams {
  sort: { label: string; value: string }
  filter: { label: string; value: string }
}

interface IParamsValue extends IParams {
  sort: string
  filter: string
}

@Component({
  components: {
    Button,
    TextInput,
    DropdownSelect,
    DataTable,
    PaginationNav,
    Loading,
    Modal,
    ConfirmationIcon,
    AddCircle,
  },
})
export default class PushNotificationPage extends Vue {
  controller = controller
  isGuest = Utils.isGuest()
  selectedId = 0
  confirmationModal = false
  filterOptions = [
    {label: 'Show All', value: ''},
    {label: 'Pending', value: 'PENDING'},
    {label: 'Published', value: 'PUBLISHED'},
    {label: 'Cancelled', value: 'CANCELLED'},
  ]
  sortOptions = [
    {label: 'Newest', value: 'desc'},
    {label: 'Oldest', value: 'asc'},
  ]
  parameters: IParamsData = {
    page: 1,
    perPage: PUSH_NOTIFICATION_PAGINATION,
    search: null,
    sort: this.sortOptions[0],
    filter: this.filterOptions[0],
  }
  headers = ['ID', 'Title', 'Publish Date', 'Target Users', 'Status']
  cancelID = ''
  title = ''

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage:
          Utils.alwaysNumber(queries.perPage) || PUSH_NOTIFICATION_PAGINATION,
        search: queries.search,
        sort:
          this.sortOptions.find(item => item.value === queries.sort) ||
          this.sortOptions[0],
        filter:
          this.filterOptions.find(item => item.value === queries.filter) ||
          this.filterOptions[0],
      }
    }

    this.fetchPNList()
  }

  @Watch('params')
  onParamsChanged(val: fakeDictionary<string | undefined>): void {
    this.$router.replace({
      query: {...val},
    })
  }

  @Watch('parameters.search')
  onKeywordChanged(val: string): void {
    if (val === '') {
      this.fetchPNList(true)
    }
  }

  get params(): IParamsValue {
    return {
      ...this.parameters,
      sort: this.parameters.sort.value,
      filter: this.parameters.filter.value,
    }
  }

  get pnDataTable(): (string | undefined)[][] {
    return controller.pnData.map(pn => [
      pn.id,
      pn.title,
      this.formatDate(pn.publishDate as string),
      this.getTargetUser(pn),
      pn.status,
    ])
  }

  private onCreatePN(): void {
    if (!this.isGuest) {
      this.$router.push({name: 'CreatePNPage'})
    }
  }

  private getTargetUser(pn: PushNotification) {
    switch (pn.targetUsers) {
      case controller.targetOptions[4].value:
        return `${pn.platformOS} ${
          platformValidationOptions.find(
            item => item.value === pn.platformValidation
          )?.label
        } v${pn.platformVersion}`
      default:
        return controller.targetOptions.find(
          item => item.value === pn.targetUsers
        )?.label
    }
  }

  private fetchPNList(resetPage = false) {
    if (resetPage) {
      this.parameters.page = 1
    }
    this.controller.getPNList(this.params)
  }

  private getSelectedTitle(id: string): string | undefined {
    return controller.pnData.find(item => item.id === id)?.title
  }

  private onCancelPN(id: string): void {
    this.confirmationModal = false
    this.cancelID = id
    this.title = this.getSelectedTitle(id) || 'notification'
    controller.cancelPN(id)
  }

  @Watch('controller.cancelTrigger')
  onTriggerChanged(val: boolean): void {
    if (val) {
      this.$notify({
        title: 'Cancel Push Notification Success',
        text: `${this.title} is cancelled`,
        type: 'success',
        duration: 5000,
      })
      this.fetchPNList(true)
    }
  }

  private formatDate(date: string): string {
    return Utils.formatDate(date, 'DD MMM YYYY HH:mm Z')
  }

  beforeDestroy(): void {
    controller.setCancelTrigger(false)
  }
}
